import styled from 'styled-components';
import Img from 'gatsby-image';
import { media } from 'theme/breakpoints';

export const Card = styled.section`
  display: grid;
  grid-template-columns: 426px 1fr;
  grid-column-gap: 64px;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 0px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    padding-bottom: 96px;
  }
  ${media.tablet} {
    padding-bottom: 48px;
    padding-top: 60px;
  }
`;

export const ProfilePhoto = styled(Img)`
  height: 100%;
  ${media.desktop} {
    display: none;
  }
`;

export const ProfileTitle = styled.div`
  & p:not(.name) {
    font-weight: 800;
    font-size: 36px;
    line-height: 56px;
    ${media.tablet} {
      font-size: 24px;
      line-height: 36px;
    }
  }
  & div.content-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    ${media.desktop} {
      flex-direction: row;
    }
    & .mobile-profile {
      display: none;
      ${media.desktop} {
        display: block;
        width: 85px;
        margin-right: 32px;
      }
      ${media.tablet} {
        width: 80px;
      }
      ${media.mobile} {
        margin-right: 24px;
      }
    }
    & img {
      max-width: 120px;
    }
    .name {
      font-size: 18px;
      line-height: 30px;
      margin-top: 13px;
      margin-bottom: 0;
      ${media.desktop} {
        margin-top: 8px;
      }
      ${media.mobile} {
        font-size: 16px;
        line-height: normal;
      }
    }
  }
`;
