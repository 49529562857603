import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'layout';
import queryString from 'query-string';
import Hero from 'components/index/hero';
import FeaturesSlider from 'components/index/featuresSlider';
import WorksWith from 'components/index/worksWith';
import StartUsing from 'components/ui/startUsing';
import GDPRbanner from 'components/index/gdprBanner';
import Modal from 'components/ui/modal';
import ProductHuntContent from 'components/layout/producthuntModal';
import CustomersRate from 'components/compare/CustomersRate';
import Testimonial from 'components/index/testimonial';
import SmartWays from 'components/index/smartWays';
import talentlyft from 'img/companyLogo/talentlyft-small.svg';
import { cards, cardsSmartWays } from 'data/index/cards';

const IndexPage = () => {
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    const data = queryString.parse(window.location.search);
    if (data.ref === 'producthunt') {
      setModalActive(true);
    }
  }, []);

  const { mario, session, heatmap, devtools, funnels } = useStaticQuery(graphql`
    query {
      mario: file(relativePath: { eq: "index/mario.png" }) {
        childImageSharp {
          fluid(quality: 99, maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      session: file(relativePath: { eq: "ecommerce/session.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      heatmap: file(relativePath: { eq: "ecommerce/heatmap.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      devtools: file(relativePath: { eq: "ecommerce/devtools.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      funnels: file(relativePath: { eq: "ecommerce/funnels.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  const images = [session, funnels, heatmap, devtools];

  return (
    <Layout
      metaTitle="LiveSession | Analytics Software and Session Replays"
      metaDescription="Learn more with LiveSession and see what the users do on your website. Watch session replays, improve UX and boost conversions with qualitative analytics."
      titleOverride
      canonical="/"
      blockScrollX
      addBanner
    >
      {isModalActive && (
        <Modal
          onClose={() => {
            // Cookies.set(HIDE_BANNER_COOKIE, '1', {
            //   expires: 10000,
            // });
            setModalActive(false);
          }}
        >
          <ProductHuntContent />
        </Modal>
      )}
      <Hero />
      <div className="container">
        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              The power of <br /> <span>qualitative analytics</span>
            </h2>
          }
          sectionDesc="Combine data from several tools to get the big picture. Draw right conclusions based on facts, not guesses."
          images={images}
          cards={cards}
        />
      </div>
      <GDPRbanner />
      <Testimonial
        img={mario}
        name="Mario Butnic"
        company="CEO TalentLyft"
        copy="With LiveSession, we investigate how similar existing features are used, so we can make the new ones even more user-friendly."
        logo={talentlyft}
        title="Mario Butnic"
      />
      <SmartWays cards={cardsSmartWays} />
      <WorksWith />
      <div className="container">
        <CustomersRate className="hero-page" sectionTitle="What customers say about us?" />
      </div>
      <StartUsing setMarginTop={{ marginTop: 40 }} />
    </Layout>
  );
};

export default IndexPage;
