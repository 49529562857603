import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const Testimonial = ({ img, name, copy, title, company, logo }) => (
  <div className="container">
    <Styled.Card>
      <Styled.ProfilePhoto fluid={img.childImageSharp.fluid} alt={title} title={name} />
      <Styled.ProfileTitle>
        <p>“{copy}“</p>
        <div className="content-container">
          <Styled.ProfilePhoto
            fluid={img.childImageSharp.fluid}
            alt={title}
            title={name}
            className="mobile-profile"
          />
          <div>
            <img src={logo} alt="logo" className="img-fluid" />
            <p className="name">
              <strong>{name},</strong> {company}
            </p>
          </div>
        </div>
      </Styled.ProfileTitle>
    </Styled.Card>
  </div>
);

export default Testimonial;

Testimonial.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  img: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};
