import React from 'react';
import Paragraph from 'components/ui/Paragraph';
import Link from 'components/ui/link';
import badge from 'img/index/GDPRbadge.svg';
import badge2 from 'img/index/CCPAbadge.svg';
import * as Styled from './styled';

const GDPRBanner = () => {
  return (
    <Styled.Section className="container">
      <Styled.Box>
        <Styled.GdprText>GDPR & CCPA</Styled.GdprText>
        <Styled.TextBox>
          <Paragraph size="l">
            Personal data protection and privacy are the core values of LiveSession.
          </Paragraph>
          <Link href="/help/privacy/" withArrow>
            Read the rules
          </Link>
        </Styled.TextBox>
        <Styled.ImageContainer>
          <Link href="/help/gdpr/">
            <img src={badge} alt="GDPR badge" title="GDPR Compiliant" className="img-fluid" />
          </Link>
          <Link href="/help/ccpa/">
            <img src={badge2} alt="CCPA badge" title="CCPA Compiliant" className="img-fluid" />
          </Link>
        </Styled.ImageContainer>
      </Styled.Box>
    </Styled.Section>
  );
};

export default GDPRBanner;
