/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import ls from '@livesession/sdk';
import TrustedBy from 'components/ui/trustedBy';
import Paragraph from 'components/ui/Paragraph';
import PlayVideoIcon from 'img/ui/playVideo.inline.svg';
import HeroImage from 'components/photos/heroImage';
import SignUpForm from 'components/ui/signupForm';
import * as Styled from './styled';

const Hero = () => {
  return (
    <Styled.Section>
      <div className="container" style={{ padding: 0 }}>
        <Styled.GridBox>
          <div className="gridbox-container">
            <p className="up-heading">ANALYTICS SOFTWARE</p>
            <h1 className="line">
              Understand how users <span>really</span> use your website
            </h1>
            <Paragraph size="xl" style={{ marginTop: 16 }}>
              LiveSession helps you analyze customers’ behavior to improve UX, increase conversion
              rates and boost revenue.
            </Paragraph>
            <Styled.PlayButton
              href="#wistia_8jeq8k92cg"
              reversed
              onClick={() => ls.track('Watch video')}
            >
              <PlayVideoIcon />
              Watch video
            </Styled.PlayButton>
            <span
              className="wistia_embed wistia_async_8jeq8k92cg popover=true popoverContent=link popoverAnimateThumbnail=true"
              style={{ postion: 'relative', display: 'inline' }}
            />
            <SignUpForm />
          </div>
          <Styled.ImageWrapper>
            <HeroImage />
          </Styled.ImageWrapper>
        </Styled.GridBox>
      </div>
      <div className="container">
        <TrustedBy hero style={{ padding: 0 }} />
      </div>
    </Styled.Section>
  );
};

export default Hero;
