import styled from 'styled-components';
import { CardContainer } from 'pages/customers';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  padding-top: 168px;
  padding-bottom: 30px;
  & h2,
  p.subtitle-text {
    text-align: center;
  }
  & p.subtitle-text {
    margin-top: 24px;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 0;
  }
  ${media.desktop} {
    padding-top: 72px;
    padding-bottom: 96px;
  }
  ${media.mobile} {
    padding-bottom: 48px;
  }
`;

export const ExtendCardContainer = styled(CardContainer)`
  margin-top: 80px;
  & .card-smart-ways {
    border: none;
    background-color: #f8f8f8;
    min-height: 470px;
    & .image-box {
      min-height: auto;
      align-items: flex-start;
      & img {
        max-width: 100%;
        border-radius: 4px 4px 0 0;
      }
    }
  }
  ${media.desktop} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 32px;
  }
  ${media.tablet} {
    margin-top: 40px;
  }
`;
