import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledParagraph } from 'components/ui/Paragraph';
import { StyledLink } from 'components/ui/link';

export const Section = styled.section`
  padding-bottom: 7.2rem;
  ${media.tablet} {
    padding: 60px 0 30px;
  }
`;

export const Box = styled.div`
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 32px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.tablet} {
    flex-direction: column;
    padding: 24px;
    margin: 0 15px;
    align-items: flex-start;
    ${StyledParagraph}, ${StyledLink} {
      font-size: 16px;
      line-height: 24px;
    }
    & img {
      display: none;
    }
  }
`;

export const TextBox = styled.div`
  ${media.desktop} {
    max-width: 48%;
  }
  ${media.tablet} {
    max-width: 100%;
  }
  & p {
    margin-bottom: 8px;
    ${media.mobile} {
      margin-bottom: 18px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    display: block;
    cursor: pointer;
  }
  & img:first-child {
    margin-right: 24px;
  }
`;

export const GdprText = styled.p`
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: normal;
  margin: 0 0 8px;
  display: none;
  ${media.tablet} {
    display: block;
  }
`;
