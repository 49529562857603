import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/customer/card';
import * as Styled from './styled';

const SmartWays = ({ cards }) => {
  return (
    <Styled.Section className="container">
      <h2>Smart ways to use LiveSession</h2>
      <p className="subtitle-text">One product, different solutions</p>
      <Styled.ExtendCardContainer>
        {cards.map((card) => (
          <Card key={card.link} {...card} className="card-smart-ways" />
        ))}
      </Styled.ExtendCardContainer>
    </Styled.Section>
  );
};

export default SmartWays;

SmartWays.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};
