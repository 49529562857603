import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const Box = styled.div`
  background-color: #fff;
  border-radius: 10px;
  position: relative;
`;

const Close = styled.span`
  position: absolute;
  font-size: 1.8rem;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
  color: #fff;
`;

const Modal = ({ children, onClose }) => {
  return (
    <Container onClick={onClose}>
      <Box onClick={(e) => e.stopPropagation()}>
        <Close onClick={onClose}>&times;</Close>
        {children}
      </Box>
    </Container>
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.string]).isRequired,
  onClose: PropTypes.func.isRequired,
};
