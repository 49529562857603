import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { media } from 'theme/breakpoints';
import event from './img/event.svg';
import stats from './img/stats.svg';

const Image = styled.div`
  & p {
    margin: 0;
    line-height: normal;
  }
`;

const Event = styled.div`
  border: 1px solid #8765f9;
  box-sizing: border-box;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 228px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 24px;
  margin-left: 24px;
  & div.event-container {
    display: flex;
    line-height: 22px;
    align-items: center;
    & img {
      margin-right: 16px;
      font-size: 16px;
      position: relative;
      top: 1px;
      text-align: center;
    }
    & div.event-content {
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      overflow: hidden;
      & p {
        font-size: 11px;
        line-height: normal;
        margin: 0;
      }
    }
    & div.time {
      color: #808080;
      font-size: 9px;
      line-height: normal;
    }
  }
`;

const User = styled.div`
  width: 329px;
  height: 64px;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-top: 24px;
  margin-left: 24px;
  display: grid;
  grid-template-columns: 36px auto;
  grid-template-rows: 1fr;
  gap: 12px;
  padding: 0 16px;
  align-items: center;
  & .gatsby-image-wrapper {
    max-width: 38px;
    max-height: 38px;
    border-radius: 50%;
  }
  & div.user-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & span {
      color: rgb(128, 128, 128);
      font-size: 12px;
    }
    & div.user-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & p.user-name {
        font-size: 14px;
        font-weight: 700;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 170px;
      }
      & div.user-city {
        display: flex;
        align-items: center;
        & p {
          margin-right: 8px;
          font-size: 12px;
          color: rgb(128, 128, 128);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 85px;
        }
        & img {
          height: 9px;
        }
      }
    }
  }
`;

const Session = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  width: calc(100% - 48px);
  height: 376px;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px;
  ${media.desktop} {
    & .gatsby-image-wrapper {
      max-width: 100%;
      max-height: 328px;
    }
  }
`;

const Stats = styled.div`
  margin-top: 24px;
  margin-right: 24px;
  width: 187px;
  height: 124px;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 155px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeroImage = () => {
  const { user, session } = useStaticQuery(graphql`
    query {
      user: file(relativePath: { eq: "photos/heroImage/img/user.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      session: file(relativePath: { eq: "photos/heroImage/img/session.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <Image>
      <FlexContainer>
        <div>
          <Event>
            <div className="event-container">
              <img src={event} alt="event" />
              <div className="event-content">
                <p>
                  Custom event: <strong>Payment</strong>
                </p>
              </div>
              <div className="time">00:14</div>
            </div>
          </Event>
          <User>
            <Img fluid={user.childImageSharp.fluid} alt="user" />
            <div className="user-container">
              <div className="user-details">
                <p className="user-name">Gavin Belson</p>
                <div className="user-city">
                  <p>San Francisco</p>
                  <img src="https://lipis.github.io/flag-icon-css/flags/4x3/us.svg" alt="" />
                </div>
              </div>
              <span>July 4th 2020, 8:45 am</span>
            </div>
          </User>
        </div>
        <Stats>
          <img src={stats} alt="stats" className="img-fluid" />
        </Stats>
      </FlexContainer>
      <Session>
        <Img fluid={session.childImageSharp.fluid} alt="session" objectFit="contain" />
      </Session>
    </Image>
  );
};

export default HeroImage;
