import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Button from 'components/ui/button';

const Content = styled.div`
  padding: 35px 90px 42px;
  background-color: #d45c39;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
  }
  & p {
    margin: 8px 0 0;
    font-size: 12px;
  }
`;

const StyledImg = styled(Img)`
  width: 200px;
  height: auto;
`;

const ProducthuntModal = () => {
  const { producthunt } = useStaticQuery(graphql`
    query {
      producthunt: file(relativePath: { eq: "producthunt.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Content>
      <h2>Special Deal for Product Hunt members!</h2>
      <StyledImg
        fluid={producthunt.childImageSharp.fluid}
        alt="Product Hunt"
        title="Special deal for Product Hunt members!"
      />
      <Button secondary center href="/signup?ref=producthunt&promo=producthunt">
        Get Lifetime Free Account
      </Button>
      <p>with 1500 sessions monthly</p>
    </Content>
  );
};

export default ProducthuntModal;
